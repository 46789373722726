import {
	FilesetResolver,
	NormalizedLandmark,
	PoseLandmarker,
	PoseLandmarkerResult,
} from '@mediapipe/tasks-vision';
import { memo, useCallback, useEffect, useRef } from 'react';
import { UseFullScreen } from '../context/FullScreen.context';
import { UseSwitchVideo } from '../context/SwitchVideo.context';
import EventEmitter from '@services/EventEmitter';
import { drawOptions, landmarksToRemove, videoSize } from '../constants';

let poseLandmarker: PoseLandmarker | null = null;
let animationFrameId: number;
const fps = 120;
const fpsInMiliceconds = 1000 / fps;
let timeOutId: NodeJS.Timeout;

function throttle<T extends (...args: any[]) => void>(
	func: T,
	delay: number,
): (...args: Parameters<T>) => void {
	let lastCall = 0;
	return function (...args: Parameters<T>): void {
		const now = performance.now();
		if (now - lastCall < delay) {
			return;
		}
		lastCall = now;
		func(...args);
	};
}
function Mediapipe() {
	const { isFullScreen } = UseFullScreen();
	const { cameraId } = UseSwitchVideo();

	const canvasRef = useRef<HTMLCanvasElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);

	const triggerResultsEvent = (
		eventName: string,
		data: NormalizedLandmark[],
	) => {
		EventEmitter.emit(eventName, data);
	};

	const throttledTriggerResultsEvent = throttle(triggerResultsEvent, 250);

	const drawCallback = useCallback(
		(results: PoseLandmarkerResult) => {
			if (!results.landmarks[0] || !canvasRef.current || !videoRef.current)
				return;
			throttledTriggerResultsEvent('results', results.landmarks[0]);

			const canvas = canvasRef.current;
			const video = videoRef.current;
			const ctx = canvas.getContext('2d');
			if (!ctx) return;

			const width = (canvas.width = video.videoWidth);
			const height = (canvas.height = video.videoHeight);

			ctx.save();
			ctx.clearRect(0, 0, width, height);
			ctx.fillStyle = 'white';

			PoseLandmarker.POSE_CONNECTIONS.forEach(({ start, end }) => {
				if (
					!landmarksToRemove.includes(start) &&
					!landmarksToRemove.includes(end)
				) {
					const s = results.landmarks[0][start];
					const e = results.landmarks[0][end];

					if (
						s.visibility < drawOptions.visibilityMin ||
						e.visibility < drawOptions.visibilityMin
					)
						return;

					const [sx, sy] = [s.x * width, s.y * height];
					const [ex, ey] = [e.x * width, e.y * height];

					ctx.globalCompositeOperation = 'destination-over';
					ctx.lineWidth = drawOptions.lineWidth;
					ctx.strokeStyle = drawOptions.color;
					ctx.beginPath();
					ctx.moveTo(sx, sy);
					ctx.lineTo(ex, ey);
					ctx.stroke();

					ctx.globalCompositeOperation = 'source-over';
					ctx.fillStyle = start % 2 === 0 ? 'rgb(0,217,231)' : 'rgb(255,138,0)';
					ctx.beginPath();
					ctx.arc(sx, sy, drawOptions.lineWidth + 2, 0, 2 * Math.PI);
					ctx.arc(ex, ey, drawOptions.lineWidth + 2, 0, 2 * Math.PI);
					ctx.fill();
				}
			});

			ctx.restore();
		},
		[throttledTriggerResultsEvent],
	);

	const predictWebcam = useCallback(() => {
		if (videoRef?.current && poseLandmarker) {
			timeOutId = setTimeout(() => {
				try {
					poseLandmarker?.detectForVideo(
						videoRef.current as HTMLVideoElement,
						performance.now(),
						drawCallback,
					);
					animationFrameId = requestAnimationFrame(predictWebcam);
				} catch (error) {
					console.error('Error on predictWebcam:', error);
					cancelAnimationFrame(animationFrameId);
					if (timeOutId) clearTimeout(timeOutId);
				}
			}, fpsInMiliceconds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const stopStreamedVideo = useCallback(() => {
		cancelAnimationFrame(animationFrameId);
		if (timeOutId) clearTimeout(timeOutId);

		if (videoRef?.current) {
			const stream = videoRef.current.srcObject as MediaStream;
			if (stream) {
				const tracks = stream.getTracks();

				tracks.forEach(track => {
					track.stop();
				});
			}

			videoRef.current.srcObject = null;
			videoRef.current.removeEventListener('loadeddata', () => {});
		}
	}, []);

	const hasGetUserMedia = () => !!navigator.mediaDevices?.getUserMedia;

	const setupCamera = useCallback(() => {
		if (!hasGetUserMedia()) {
			console.warn('getUserMedia() is not supported by your browser');
			return;
		}

		if (!poseLandmarker) {
			console.warn('Wait! poseLandmaker not loaded yet.');
			setTimeout(setupCamera, 1000);
			return;
		}

		const constraints = {
			video: {
				deviceId: {
					exact: cameraId as string,
				},
				width: videoSize.width,
				height: videoSize.height,
				frameRate: {
					ideal: 15,
					max: 20,
				},
			},
			audio: false,
		};

		navigator.mediaDevices.getUserMedia(constraints).then(stream => {
			if (videoRef?.current) {
				videoRef.current.srcObject = stream;
				videoRef.current.addEventListener('loadeddata', predictWebcam);
			}
		});
	}, [cameraId, predictWebcam]);

	const createPoseLandmarker = useCallback(async () => {
		const vision = await FilesetResolver.forVisionTasks(
			'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm',
		);
		poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
			baseOptions: {
				modelAssetPath:
					'https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_full/float16/latest/pose_landmarker_full.task',
				delegate: 'CPU',
			},
			runningMode: 'VIDEO',
			numPoses: 1,
			minPoseDetectionConfidence: 0.6,
			minTrackingConfidence: 0.6,
		});
	}, []);

	useEffect(() => {
		createPoseLandmarker();
		return () => {
			stopStreamedVideo();
		};
	}, [createPoseLandmarker, stopStreamedVideo]);

	useEffect(() => {
		if (cameraId) {
			stopStreamedVideo();
			setTimeout(() => {
				setupCamera();
			}, 1000);
		}
	}, [cameraId, setupCamera, stopStreamedVideo]);

	return (
		<>
			<video
				ref={videoRef}
				autoPlay
				playsInline
				muted
				onLoadedMetadata={() => {
					if (canvasRef.current && videoRef.current) {
						canvasRef.current.width = videoRef.current.videoWidth;
						canvasRef.current.height = videoRef.current.videoHeight;
					}
				}}
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: 'auto',
					objectFit: 'cover',
					zIndex: 1,
					maxWidth: isFullScreen ? '100%' : 1280,
					pointerEvents: 'none',
				}}
			/>
			<canvas
				ref={canvasRef}
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					maxWidth: isFullScreen ? '100%' : 1280,
					width: '100%',
					height: 'auto',
					zIndex: 2,
					pointerEvents: 'none',
				}}
			/>
		</>
	);
}

export default memo(Mediapipe);
