import { memo } from 'react';
import { UseFullScreen } from '../context/FullScreen.context';
import Mediapipe from './Mediapipe';

function Capture() {
	const { isFullScreen } = UseFullScreen();

	return (
		<div
			style={{
				maxWidth: isFullScreen ? '100%' : 1280,
				width: '100%',
				aspectRatio: '16/9',
				display: 'block',
				zIndex: 2,
				position: 'relative',
			}}>
			<Mediapipe />
		</div>
	);
}

export default memo(Capture);
